import React, { useEffect, useState } from "react";
import { InputLabel, Title } from "../../Texts";
import InputWithLabel from "../../input/inputWithLabel";
import DefaultDropMenu from "../../input/droplist";
import { TypeRules } from "./TypeRules";
import { TargetRules } from "./TargetRules";
import SwicthInput from "../../input/swicth";
import { Rangeinput } from "../../input/rangeInput";
import PrimaryButton from "../../Button1/primarybutton";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../store";
import { TimezoneJSON } from "../../../content/timezone";
import { isEqual, isNotEmpty } from "../../../utils/helpers";
import toast from "react-hot-toast";
import { listOfIcon } from "../../../content/iconList";






export const RulesSettings = ({ setTargetSetting = null }) => {

  const displayRules = ["showOnLoad", "showOnExit", "clickTrigger", "showOnScroll", "onInactivity"]
  const CTTAttr = ['class', 'id', 'custom']
  const duration =["weeks", "days", "hours"]
  const matchtype = ["any", "all"]

  const [curDisplay, setCurDisplay] = useState("showOnLoad")

  const DisPatch = useDispatch()

  const [active,setactive] = useState(false)

  const { Camp_info } = useSelector(state => ({ Camp_info: state?.Campaign_info }))

  const Camp_rule = Camp_info?.rule ?? {}
  const reapperTimeVisit = Camp_rule?.reapperTimeVisit ?? {}
  const ruleMatchtypeInd = matchtype.findIndex(list => list == Camp_rule?.ruleMatchType )
  const reapperTimeVisitDT = duration.findIndex(list => list == reapperTimeVisit?.DurationType )

  
  useEffect(()=>{
    setTimeout(()=>setactive(true),10)
  },[])


  const AddProp = (fieldProp) => DisPatch(Actions?.setAddRulesArrContent({fieldProp}))
  const RomoveProp = (fieldProp,index) => DisPatch(Actions?.setRemoveRulesArrContent({fieldProp,index}))

  const nestArrCC = ( fieldProp, index, key, value ) => {
    // TimeZone TEXT to  UTC conversion
    let ruleType=""
    if(key==="ruleType")  ruleType=value
    else ruleType=Camp_info?.rule?.targetRules[index]?.ruleType

    if(ruleType==="timezone" && isNotEmpty(value) ){
      TimezoneJSON.map((obj, inx)=>{
        if(isEqual(obj?.text, value)) value= obj.utc.join(", ")
              })
    }

    // Posting data
    const Payload = { 
      fieldProp, 
      index, 
      key, 
      value 
    }

    DisPatch(Actions?.SetDRStateArrChange(Payload))


  }
  const SaveClick = () => {
    DisPatch(Actions.setUpdateCampaign())
    toast.success("Successfully saved!")
  }

  
  const DisplayRuleStateCC = (type,value) => {

    DisPatch(Actions?.SetDRStateChange({type,value}))

  }

  return (
    <div className="fixed top-[75px] bottom-0 left-0 flex w-full h-full  bg-backgroundShadow justify-end" style={{ zIndex: '200' }}>

      <div className={`relative transform duration-700 delay-50 ${active ? "w-[70%]" : "w-[0%]"} top-[3px]  h-full flex flex-col p-4 px-6 rounded-l-lg bg-white  space-y-4 shadow-md `}>

        <div className=" w-full flex justify-between items-center">

          <div className=" text-[#06152D] font-medium font-sans text-[20px] noselect capitalize">Display & targeting rules</div>

          <div className="flex space-x-2" >
            <PrimaryButton text={'Cancel'} width="w-24" buttontype="secondarybutton" OnClick={() => { setTargetSetting(false);setactive(false) }} />
            <PrimaryButton text={'Save'} width="w-24" OnClick={SaveClick} />
          </div>



        </div>

        <div className={`transform duration-1000 delay-50 ${active ? "opacity-100" : "opacity-0"} w-full h-full pb-20 overflow-x-hidden overflow-auto list-industry`}>

          <div className="flex flex-col space-y-3">

            <Title text="When to show the popup / campaign ?" margin="mb-1 mt-4" textSize={"text-[16px]"} fontStyle={"font-bold"} />

            {/* <InputRadiocheck textStyle="text-[#06152D] font-[400] font-[DM Sans]"  fieldType={"timerType"}  displayRules={displayRules} textValue={"Default"} /> */}

            <div className="space-y-3">

              <SwicthInput label="Show on page load" status={Camp_rule?.onPageLoad ?? false} onchange={ (state) => DisplayRuleStateCC('onPageLoad',state) } />

              <SwicthInput label="Show on exit" status={Camp_rule?.onExit ?? false} onchange={ (state) => DisplayRuleStateCC('onExit',state) } />

              <SwicthInput label="Click to trigger" status={Camp_rule?.onClickToTrigger ?? false} onchange={ (state) => DisplayRuleStateCC('onClickToTrigger',state) } />

              { Camp_rule?.onClickToTrigger && <div className="flex flex-col ">
                {
                  Camp_rule?.CTTProp.map((CTT,index) => { 
                    const ActiveCCTProp = CTTAttr?.findIndex( list => list == CTT?.attrType )
                    const isCustom = (CTT?.attrType == 'custom')
                    const DataVAlue = CTT?.dataValue

                    return (
                    <div className="flex space-x-2">


                      <DefaultDropMenu list={CTTAttr} active={(ActiveCCTProp < 0) ? 0 :ActiveCCTProp} label="" labelTextsize='hidden' spaceY="" SelectIndex={true} onchange={(ind)=> nestArrCC('CTTProp',index,'attrType',CTTAttr[ind]) } />

                      { isCustom && <InputWithLabel label="" labelTextsize='hidden' spaceY="" Placeholder="Ex:data-id" textvalue={DataVAlue?.attr} onchange={(e)=>nestArrCC('CTTProp',index,'dataValue' , { attr: e.target.value , value:DataVAlue?.value }) } /> }

                      <InputWithLabel label="" labelTextsize='hidden' spaceY="" Placeholder="Ex:Primary-bnt" textvalue={isCustom ? DataVAlue?.value: CTT?.value} onchange={(e)=>nestArrCC('CTTProp',index, isCustom ? 'dataValue' :'value',isCustom ? { attr:DataVAlue?.attr , value:e.target.value } :e.target.value)} />

                      <div className=" cursor-pointer rounded-md h-[37px] bg-[#ffffff] border-[#fb3c3c] border-1 aspect-square flex justify-center items-center  " onClick={()=>RomoveProp('CTTProp',index)} >
                      { listOfIcon('delete',20,20,'#fb3c3c') }

                      </div>
                    </div>
                    )
                  })
                }
                <PrimaryButton text="add" width="w-20" OnClick={()=>AddProp('CTTProp')} />
              </div> } 
              <SwicthInput label="Show on scroll" status={Camp_rule?.onScroll ?? false} onchange={ (state) => DisplayRuleStateCC('onScroll',state) } />
              {Camp_rule?.onScroll && <Rangeinput  mainWidth="w-[300px]" primaryColor="#2463EB" secondaryColor="white" mainBlockclass="h-[100px] my-auto ml-4" maxValue={100} minValue={0} defaultValue={Camp_rule?.scrollTriggerPoint || 0} OnChange={(e) => DisplayRuleStateCC("scrollTriggerPoint",e.target.value)} /> }
              <SwicthInput label="Show on Inactivity" status={Camp_rule?.onInactive ?? false} onchange={ (state) => DisplayRuleStateCC('onInactive',state) } />
              {Camp_rule?.onInactive && <InputWithLabel label="Seconds" labelTextWeight="my-auto" value={Camp_rule?.inactiveTime} mainclass="flex space-x-2" width={"200px"} onchange={(e)=>DisplayRuleStateCC('inactiveTime',e.target.value)} Placeholder="Ex: 0 " />}

            </div>

          </div>



          {Camp_rule?.onPageLoad && <>
            <Title text="Show popup after visitor spends" margin="mt-7" textSize={"text-[16px]"} fontStyle={"font-bold"} />
            <Title text="( How much time does the visitor have to spend on a page before the popup appears. )" margin="mb-1" textSize={"text-[14px] text-[#586474]"} fontStyle={"font-normal"} />
            <InputWithLabel textvalue={Camp_rule?.spendTime || 0} min="0" label="Seconds" mainclass="flex flex-row  items-center " labelTextWeight="mr-3 text-[#06152D] font-[400] font-[DM Sans] text-[15px]" Placeholder="0" type={"number"} width={"170px"} onchange={(e)=>DisplayRuleStateCC('spendTime',e.target.value)} /></>
          }

          <Title text="When the popup should re-appear to the same visitor ?" margin="mt-7" textSize={"text-[16px]"} fontStyle={"font-bold"} />
          <Title text="( When a visitor has seen this popup, how long we should wait before showing the popup for the same visitor. )" margin="mb-1" textSize={"text-[14px] text-[#586474]"} fontStyle={"font-normal"} />
          <div className="flex flex-row w-full ">
            <InputWithLabel min="0" label="" mainclass="flex flex-row  items-center " labelTextWeight="" Placeholder="0" type={"number"} width={"200px"} textvalue={reapperTimeVisit?.value} onchange={(e)=>DisplayRuleStateCC('reapperTimeVisit',{value:e.target.value,DurationType:reapperTimeVisit?.DurationType})} />
            <DefaultDropMenu label={''} mainclass="flex space-x-4" labelTextWeight="my-auto" list={["weeks", "days", "hours"]} active={reapperTimeVisitDT} SelectIndex={true} onchange={(index)=>DisplayRuleStateCC('reapperTimeVisit',{value:reapperTimeVisit?.value,DurationType:duration[index]})} />
          </div> 



          <Title text="When the popup should re-appear to the same subscriber ?" margin="mt-7" textSize={"text-[16px]"} fontStyle={"font-bold"} />

          <Title text="( When a visitor has submitted the form in this popup, how long we should wait before showing the popup for the same subscriber. )" margin="mb-1" textSize={"text-[14px] text-[#586474]"} fontStyle={"font-normal"} /> 

          <div className="flex flex-row w-full ">
            <InputWithLabel  textvalue={Camp_rule?.reapperTimeSub || 0} min="0" label="Days" mainclass="flex flex-row space-x-2 items-center " labelTextWeight="" Placeholder="0" type={"number"} width={"200px"} onchange={(e)=>DisplayRuleStateCC('reapperTimeSub',e.target.value)} />
            {/* <DefaultDropMenu label={''} mainclass="flex space-x-4" labelTextWeight="my-auto" list={["Weeks", "Days", "hours"]} /> */}
          </div>

          <Title text="Targeting rules" margin="mb-1 mt-3" />
          <DefaultDropMenu label={'Match type'} mainclass="flex space-x-4 mb-1  flex-row " active={ruleMatchtypeInd} SelectIndex={true} onchange={(index)=>DisplayRuleStateCC('ruleMatchType',matchtype[index])} labelTextWeight="my-auto noselect  text-[#06152D] font-[400] font-[DM Sans] text-[14.5px]" list={["any", "all"]} />

          <TargetRules Rules={Camp_rule?.targetRules ?? [] } contentCC = {nestArrCC} DeleteRule={(index)=>RomoveProp('targetRules',index)} AddRules={()=>AddProp('targetRules')} />

        </div>

      </div>
    </div >
  )
} 