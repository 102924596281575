import React, { useEffect, useRef, useState } from "react";
import TextareaInput from "../../input/textarea";
import { opertatorData } from "../../../content/formulaOperator";
import { CalculaterResult } from "../../../utils/Calculater";
import { useDispatch } from "react-redux";
import { Actions } from "../../../store";
import { toast } from "react-hot-toast";

export const FormulaData = ({
    question=[],
    formula=[""],stageResult=0,fieldId='',setFormulaData=null,setMessage=null,setIsvaild=null,IsVaild=true,message='',formuladata=''
}) => {

    const Dispatch = useDispatch()
    const opertator = opertatorData()
    // const [IsVaild,setIsvaild] = useState(true)
    // const [message,setMessage] = useState("")
    // const [formuladata,setFormulaData] = useState(formula[stageResult])
    // const [text, setText] = useState("");
    const [sampleData,setsamplaData] = useState("")
    const [SumVAlue,setsumValue]=  useState(0)

    const TypeFormula =  (e) => {

        let Value = String(e.target.value).toLowerCase()
        setFormulaData(e.target.value)

        const CalculaterData = new CalculaterResult({question:question});
        const IsValidINput =  CalculaterData.userInputFormula(Value)

        setIsvaild(IsValidINput.status)
        setMessage(IsValidINput.message)
        setsamplaData(IsValidINput.sample)

    }

    useEffect(()=>{
        try{
            let Sum = eval(sampleData)
            setsumValue(Sum)
        }catch(err) {
            setIsvaild(false)
            setMessage("InVAlid Input")
        }
    },[sampleData])

    const addReg = (type) => {
        let concact = ""
        let allAue = ""
        for(let i =0 ;i < question.length;i++ ){
            if(question[i]?.InPutType !== "email")allAue += `q${i+1}${i==question.length-1?"":','}`
        }
        if(String(type).toLowerCase() == "add") concact ="+"
        if(String(type).toLowerCase() == "subtract") concact ="-"
        if(String(type).toLowerCase() == "multiply") concact ="*"
        if(String(type).toLowerCase() == "divide") concact ="/"
        if(String(type).toLowerCase() == "sum") concact =`(sum(${allAue}))`
        if(String(type).toLowerCase() == "average") concact =`(ave(${allAue}))`
        if(String(type).toLowerCase() == "min") concact =`(min(${allAue}))`
        if(String(type).toLowerCase() == "max") concact =`(max(${allAue}))`
        if(String(type).toLowerCase() == "pow") concact =`(pow(q1,2))`
        setFormulaData(formuladata+concact)
        const CalculaterData = new CalculaterResult({question:question});
        const IsValidINput =  CalculaterData.userInputFormula(formuladata+concact)

        setIsvaild(IsValidINput.status)
        setMessage(IsValidINput.message)
        setsamplaData(IsValidINput.sample)
        //{eval(sampleData)}
    }

    const Refdata = useRef();  
//{eval(sampleData)}
    return(
        <div className="w-full space-y-4 relative">
            <div className={`space-y-3`}>
            <label className={`font-semibold"capitalize`}>Type Formula</label>
            <textarea className={`gz-basic-input tracking-wider uppercase ${IsVaild?"":"border-red-600"} h-[300px] block w-full p-2 text-black appearance-none  bg-[#F9FAFB] border-2" outline-none  placeholder-gray-600 border rounded-8px  px-14px leading-5 placeholder-opacity-40  resize-none border-secondar`} value={formuladata} onChange={TypeFormula}/>
            {IsVaild && <p className="capitalize py-2 "><span className="capitalize">sample Sum : </span><span className="text-primary pr-3">{sampleData}</span> Finial Result : <span className="text-primary text-[16px] font-medium" >{SumVAlue}</span></p>  }       
         </div>
            {!IsVaild && <p className={`text-red-500 absolute -top-3 right-0 capitalize`}>{message}</p>}
            <div className="grid grid-cols-4 gap-4" >
                {
                    opertator.map((list,index)=>{
                        return(
                            <div key={index} className="flex px-3 py-5 flex-col justify-center space-y-1.5 border-1 cursor-pointer rounded-md" onClick={() => addReg(list?.type) } >
                                <span className="flex mx-auto" >{list?.icon}</span>
                                <span className="flex mx-auto" >{list?.type}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}