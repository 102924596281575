import { configureStore, createSlice } from "@reduxjs/toolkit";
import { createTemplate, liveOrPauseCampaign, updateCampaign } from "../actions/templates";
import _, { identity } from "lodash";
import { isNotEmpty } from "../utils/helpers";
import { commonFileds } from "../content/commenfileds";
import toast from "react-hot-toast";
import { TUTORIAL_STORAGE, checkAndGetTutorial } from "../utils/tutorialStorage";
import { getCustomDomain } from "../actions/site";
import { CreateOauthList } from "../actions/integrations";
import { updateUserNotify } from "../actions/user";

let starttrigger , isCampaignReadyToUpdate = false;

const TriggerCampaignUpdate = ( paytload, Campaign_id, SharedWS = false )=>{

    if( isCampaignReadyToUpdate ) clearTimeout( starttrigger );

    isCampaignReadyToUpdate = true;

    starttrigger = setTimeout( () =>  { updateCampaign( paytload, Campaign_id, SharedWS ); isCampaignReadyToUpdate=false }, 1800 )

}


const cunerSlice = createSlice({
    name: "Engage",
    initialState: {
        userDetail: {
            profile: "",
            email: "",
            last_name: "",
            first_name: "",
            engage_user_role: "admin",
            primary_Site_id:"",
            plan:"free"
        },
        introUserInfoGS: {},
        introSetupData : {},
        autosaving:false,
        defaultCCtProp:{
            attrType: "class",// || "id" || "other",
            value: "Primary-bnt", //|| {
                // attr:"",
                // value:""
           // }
        },
        customDomain:[],
        defaultTatgetRules:{
            ruleType :"url" ,  // [ 'url','country','pagecount','cookie','timezone' ],
            // condiction : "",
            // value:"",
            // isCookie:null  
        },
        DefaultRule:{
            onPageLoad:true,
            onExit:false, // booleam
            onClickToTrigger:false, // booleam
            onScroll:false, 
            onInactive:false,
            scrollTriggerPoint:0,
            inactiveTime:0,
            CTTProp : [{
                attrType: "class",// || "id" || "other",
                value: "Primary-bnt", //|| 
                dataValue:{
                    attr:"",
                    value:""
               }
            }],
            spendTime: 0,
            reapperTimeVisit:{
                value:0,
                DurationType:"hours",//||"days" || "custom"
            },
            reapperTimeSub:0,
            onDesktop:true,
            onTablet:true,
            onMobile:true,
            ruleMatchType:"all",// || "any",
            targetRules:[
                // {
                   // ruleType :"url" ,  // [ 'url','country','pagecount','cookie','timezone' ],
                    //condiction : "",
                    //value:"",
                    // cookieName:'',
                    // cookieNameCondiction:'',
                    // cookieValue:'',
                    // cookieValueCondiction:'',

                    //isCookie:null //|| {
                    //     condiction : "",
                    //     value:"",
                    // }
               // },
            ]
        },
        SharedWS:false,
        SharedWS_id:'',
        projects:[],
        sharedProject:[],
        planCalculation:{},
        userNotification:[],
        userPlan:{},
        sitesInfo: [],
        teamUsers:[],
        completedTutorial:checkAndGetTutorial(),
        engage_apptour:null,
        domain: ['Select Domain'],
        device: 'desktop',
        Campaign_info: {
            Campaign_id: '',
            name: 'My Project',
            project_id:"",
            status: "paused",
            campaign_update: "false",
            rule:null,
            additional_info: {},
            pollResp:{}
        },
        campaignSettings:{
            "fontSize":"medium",
            "fontFamily":"DM Sans",
            "spaceBetweenTheBlock":2,
            "backgroundType":"image",
            "image":"",
            "color":"#ffffff",
            "letsStartImg":"/asset/lets-start.png", 
            "timesupImg":"/asset/times-up.png", 
            "readyImg":"/asset/ready.png", 
            "PlayImg":"/asset/play.png",
            "defaultContent":{
                "backButtonContent":"Previous",
                "nextButtonContent":"Next",
                "scoreContent":"Score",
                "TimingContent":"your Timing",
                "TimerContent":"Timer",
                "NOAContent":"Number of action",
                "correctAnswer":"Correct Answer is",
                "nextQuestion":"Next Question",
                "totalVote":"Total Vote",
                "voteCount":"Vote Count",
                "nextQuestionButtonContent":"Next Question",
                "questionsBlockcontent":"Questions",
                "answeredBlockcontent":"Answered",
                "fieldRequied":"This Field is Requied",
                "invaildNumber":"Invaild number",
                "invaildEmail":"Invaild Email"
            }
        },
        Campaign_integration:[],
        EditorFields: [],
        EditorEvent: {
            "total_views": 0,
            "total_clicks": 0,
            "total_subscribe": 0
        },
        EditorRenderDevice: {
            overall: {
                data: [0, 0, 0],
                backgroundColor: ["#2563EB", "#FDC500", "#8AC926"],
                borderWidth: 0,
            }
        },
        integrationMailerObj:{
            mailer:"",
            list:[],
            field:[]
        },
        activeQuestion:{
            screen:"start",
            index:0
        },
        ActiveField:{
            filedId:"",
            index:0,
            ActiveScreen:"common",
            activeQuesindex:0
        },
        ActiveFieldSide:{
            filedId:"",
            index:0,
            ActiveScreen:"common",
            activeQuesindex:0
        },
        chooseTemplate:false,
        fields: {},
        fieldsOrder: [],
        editorFiled: false,
        isEcommerceFields: false,
        createTemplate: false,
        EcommerceFieldID: "",
        additional_info: {},
        oauthIntegrationStatus:{
            status:"pending",
            mailer:""
        }

    },

    reducers: {
        setActiveField(state,action){
            state.ActiveField.filedId = action.payload.filedId
            state.ActiveField.index = action.payload?.index ?? 0
            state.ActiveField.ActiveScreen = action.payload?.ActiveScreen ?? "common"
            state.ActiveField.activeQuesindex = action.payload?.activeQuesindex ?? 0
        },
        userNotificationViewed ( state, action ){ 

            state.userNotification = state.userNotification?.map( list => {
                let obj = list
                obj.is_viewed = "true";
                return obj
            } )

            updateUserNotify()

        },
        putCampaignSettings( state, action ) {

            const Settings =  action.payload;

            let GetKeys =  Object.keys( Settings );

            let GetSCkeys=  Object.keys( Settings?.defaultContent ?? {} );

            GetKeys.map( list => {

                if( list !== "defaultContent" ) state.campaignSettings[ list ] = Settings[ list ];

            } )

            GetSCkeys.map( list => {

                state.campaignSettings.defaultContent[ list ] = Settings?.defaultContent[ list ];
                
            } )

        },
        fetchCustomDomainFromStore( state,action ) {

            state.customDomain = action.payload

        },
        campaignSettingsCC ( state, action ) {

            if(!state.autosaving) state.autosaving = true

            const { type ,value } = action?.payload;

            state.campaignSettings[type] = value;

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings)
            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)

        },
        campaignSettingsDCCC ( state, action ) {

            if(!state.autosaving) state.autosaving = true

            const { type ,value } = action?.payload;

            state.campaignSettings.defaultContent[type] = value;

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings)
            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)

        },
        triggerOauthStatus( state, action ) {

            const { status, mailer, code } = action.payload;

            state.oauthIntegrationStatus.status = status;
            state.oauthIntegrationStatus.mailer = mailer;
            state.oauthIntegrationStatus.code = code;

        },

        addOauthIntegration( state, action ){

            try{

                const tempObj = _.cloneDeep( state.fields )

                const { mailer, lists, credentials } = action.payload;

                let fieldIds = Object.keys( tempObj );

                let integration_list = {};

                let Props = Object.values(tempObj).map( (fields,index) => {

                    let isThisGameType = ( fields.category == 'games' || fields.category == 'spinWheel' );
    
                    const questionType = ( fields.type == 'calculater') ? 'questionsList' : 'questions';

                    integration_list[lists[index]] = { innerSheetName:fields.type, selectedFields:[ fieldIds[index], ],  selectedFieldsTagconfig:{} }
    
                    let fieldsprop = isThisGameType ? fields.filedContent?.game_leadForm_json?.field: fields.filedContent?.game_mainpage_json[questionType];
               

                    fieldsprop?.map( ( list,ind ) =>  {

                        let key = Math.random().toString(16).slice(8) + Date.now().toString(36);

                        let quesId = list?.questionId;

                        if( !quesId ) {
                            state.fields[fieldIds[index]].filedContent[ !isThisGameType ? 'game_mainpage_json' :"game_leadForm_json"][!isThisGameType?questionType:"field"][ind]['questionId'] = key ;
                        }
                    
                        const payload = {
                    
                            name:"",
                            field_id:"default",
                            connection_id: list?.questionId ?? key
                    
                        }

                        integration_list[lists[index]].selectedFieldsTagconfig[ fieldIds[index] ] = payload;

                        return key;


                    } )

                    return ({ name: fields.type, id:fieldIds[index], fields:fieldsprop })
    
                } );



                let defaultObj = {
                    mailer,
                    credentials: {
                        oauth:{
                            ...credentials
                        }
                    },
                    integration_list

                }

                state.Campaign_integration.push(defaultObj);
                

            }catch(Err){
                // console.log(Err)
            }

        },


        setIntegrationmailerObj( state, action ) {
            state.integrationMailerObj.mailer = action.payload.mailer;
            state.integrationMailerObj.list = action.payload.list;
            state.integrationMailerObj.field = action.payload.field;
            state.integrationMailerObj.workspace = action.payload.workspace;
        },

        pushcampaingIntegration( state, action ){

            state.Campaign_integration = [...state?.Campaign_integration, action?.payload];

        },
        addCampaignIntegratio ( state, action ) {

            const { credentials, mailer } = action?.payload ;

            const getCampIntegration = [...state?.Campaign_integration]

            const getObjectindex = state?.Campaign_integration?.findIndex( obj => obj?.mailer == mailer ) ;

            if( getObjectindex < 0 ) {

                let defaultObj = {
                    mailer,
                    credentials,
                    integration_list:{},

                }

                 state?.Campaign_integration.push( defaultObj );

                 return ;

            }

            let getObject = getCampIntegration[getObjectindex];

            getObject['credentials'] = credentials;

             state.Campaign_integration[getObjectindex] = getObject;

            
        },

        clearCacheCampaign_integration( state, action ) {
            state.Campaign_integration = []
        },

        onTagconfig ( state, action ) {

            try {

                let key = Math.random().toString(16).slice(8) + Date.now().toString(36);

                const { mailer, fieldId, type, selectedList, name, field_id, queInd } = action.payload; 

                let isThisGameType = ( type == 'games' || type == 'spinWheel' );

                const questionType = (type == 'calculater') ? 'questionsList' : 'questions';

                let  getQuestion = !isThisGameType ? state?.fields[fieldId]?.filedContent?.game_mainpage_json[questionType][queInd] : state?.fields[fieldId]?.filedContent?.game_leadForm_json?.field[queInd] ;

                if( !getQuestion?.questionId ) state.fields[fieldId].filedContent[ !isThisGameType ? 'game_mainpage_json' :"game_leadForm_json"][!isThisGameType?'questions':"field"][queInd]['questionId'] = key ;


                const payload = {

                    name,
                    field_id,
                    connection_id: getQuestion?.questionId 

                }

                getQuestion = !isThisGameType ? state?.fields[fieldId]?.filedContent?.game_mainpage_json[questionType][queInd] : state?.fields[fieldId]?.filedContent?.game_leadForm_json?.field[queInd] ;

                const getCampIntegration = [...state?.Campaign_integration]

                const getObjectindex = state?.Campaign_integration?.findIndex( obj => obj?.mailer == mailer ) ;

                if( getObjectindex < 0 ) { toast.error('Can\'t Find the mailer') ; return };
            
                let getObject = getCampIntegration[getObjectindex];

                let selectedFieldsTagconfig = getObject?.integration_list[selectedList]?.selectedFieldsTagconfig[fieldId] ;

                if ( !selectedFieldsTagconfig[field_id] )  selectedFieldsTagconfig[field_id] = payload ;

                else selectedFieldsTagconfig[field_id].connection_id = getQuestion?.questionId;

                getObject.integration_list[selectedList].selectedFieldsTagconfig[fieldId] = selectedFieldsTagconfig ;

                state.Campaign_integration[getObjectindex] = getObject

                toast.success('Field updated!')


            }catch( err ) {
                // console.log( err )
            }

        },

        onCampaignIntegrationListCC( state, action ) {

            const { mailer, list_id, filedId, name, list_obj, actionType } = action?.payload;

            const getCampIntegration = [...state?.Campaign_integration]

            const getObjectindex = state?.Campaign_integration?.findIndex( obj => obj?.mailer == mailer ) ;

            if( getObjectindex < 0 ) {  toast.error('Can\'t Find the mailer') ; return }

            let getObject = getCampIntegration[getObjectindex];

            if ( actionType == "add" ) {

                let getlist = getObject?.integration_list;

                if( !getlist[list_id] ) {

                    let Tagconfig  = {}
                    
                    Tagconfig[ filedId ] = {}
    
                    getlist[list_id] = { ...list_obj, selectedFields:[ filedId ], selectedFieldsTagconfig: Tagconfig };
    
                    state.Campaign_integration[getObjectindex] = {...getObject, integration_list: {...getlist} };

                    toast.success(`field added to the list.`)

                    return ;

                }

                let selectedFields = [ ...getObject?.integration_list[list_id]?.selectedFields ];

                const getid = selectedFields?.findIndex( list => list == filedId )

                if( getid < 0 ) selectedFields=[ ...selectedFields, filedId ]

                let selectedFieldsTagconfig = { ...getObject?.integration_list[list_id]?.selectedFieldsTagconfig } ;

                selectedFieldsTagconfig[filedId] = {}

                getObject.integration_list[list_id] = { ...getObject?.integration_list[list_id], selectedFields,selectedFieldsTagconfig }

                state.Campaign_integration[getObjectindex] = { ...getObject, integration_list: getObject?.integration_list  };

                toast.success(`field added to the list.`)
                return 

            }

            if ( actionType == "remove" ) {

                let selectedFields = [...getObject?.integration_list[list_id]?.selectedFields];

                let selectedFieldsTagconfig = {...getObject?.integration_list[list_id]?.selectedFieldsTagconfig};


                let getFiledIndex = selectedFields?.findIndex( list => list == filedId );

                if( getFiledIndex > 0 ) selectedFields.splice( getFiledIndex, 1 ) ;

                if( selectedFieldsTagconfig[filedId] ) delete selectedFieldsTagconfig[ filedId ]

                getObject.integration_list[list_id] = { ...getObject?.integration_list[list_id], selectedFields, selectedFieldsTagconfig }

                state.Campaign_integration[getObjectindex] = { ...getObject, integration_list: getObject?.integration_list  }
                
                toast.success(`field Remove from the list.`);

                 return

            }

        },

        onListTagConfgCC ( state, action ){

            const { mailer, list_id, filedId, tag } = action?.payload;

        },


        setIntroUserInfoGS(state,action){
            state.introUserInfoGS = action.payload
        },
        setIntroSetupData(state,action){
            state.introSetupData = action.payload
        },
        setDefaultRuletoCamp(state,action){
            state.Campaign_info.rule = state.DefaultRule
        },
        SetDRStateChange(state,action){

            let rule = {...state.Campaign_info.rule } 
            const { payload } = action
            rule[payload?.type] = payload?.value

            state.Campaign_info.rule = rule
            
        },
        setcompletedTutorial (state,action){
            state.completedTutorial=action?.payload?.data || {}
         },
         setengage_apptour (state, action){
            if(state.engage_apptour===null) state.engage_apptour=action?.payload || false
         },

        SetDRStateArrChange(state,action){

            let rule = {...state.Campaign_info.rule } 

            const { payload } = action

            const { fieldProp, index, key, value } = payload

            if( (fieldProp == "CTTProp") && (key == 'custom') ) rule[fieldProp][index][key][value?.key] = value?.value

            // else if( (fieldProp == "targetRules") && (key == 'cookie') )  rule[fieldProp][index][key][value?.key] = value?.value

            else rule[fieldProp][index][key] = value

            state.Campaign_info.rule = rule
            
        },
        setAddRulesArrContent (state,action) {

            let rule = {...state.Campaign_info.rule } 
            let key = Math.random().toString(32).slice(4) + Date.now().toString(36);


            const { payload } = action;
            const { fieldProp } = payload

            if( fieldProp === "CTTProp" ) rule[fieldProp]?.push(state.defaultCCtProp)
            if( fieldProp === "targetRules" ) rule[fieldProp]?.push({id:key,...state.defaultTatgetRules})
            
            state.Campaign_info.rule = rule

        },

        setRemoveRulesArrContent (state,action) {

            let rule = {...state.Campaign_info.rule } 

            const { payload } = action;
            const { fieldProp, index } = payload

            rule[fieldProp].splice(index, 1)
            
            state.Campaign_info.rule = rule

        },

        setActiveFieldside(state,action){
            state.ActiveFieldSide.filedId = action.payload.filedId
            state.ActiveFieldSide.index = action.payload?.index ?? 0
            state.ActiveFieldSide.ActiveScreen = action.payload?.ActiveScreen ?? "common"
            state.ActiveFieldSide.activeQuesindex = action.payload?.activeQuesindex ?? 0
        },
        setSharedWS(state,action){
            state.SharedWS = action.payload
        },
        setSharedWSID(state,action){
            state.SharedWS_id = action.payload
        },
        setChooseTemplate(state,action){
            state.chooseTemplate = action.payload
        },
        setuserDelailsUpDate(state, action){
            state.userDetail[action.payload?.type] = action.payload.value
        },
        setPlanCalculation(state, action){
            state.planCalculation = action.payload
        },
        PlaUpdateTrigger(state, action){
            state.planCalculatio = {}
            state.userPlan['plan'] = action.payload?.plan 
            state.userPlan['type'] = action.payload?.type 
        },
        setProjects(state,action) {
            state.projects = action.payload
        },
        setSharedProjects(state,action) {
            state.sharedProject = action.payload
        },
        setTeamUsers (state, action) {
            state.teamUsers = action.payload
        },
        setUpdateLeadConatent(state, action) {
            // console.log(action.payload)
            if(!state.autosaving) state.autosaving = true
            try{
                let id = state.fields
                let _idclone = _.cloneDeep(id[action.payload?.fieldId])
                if (!_idclone["filedContent"]["game_leadForm_json"]["field"]) _idclone["filedContent"]["game_leadForm_json"]['field'] = []
                _idclone["filedContent"]["game_leadForm_json"]["field"].push(action.payload["listOfData"])
                state.fields[action.payload?.fieldId]["filedContent"]["game_leadForm_json"]["field"] = _idclone["filedContent"]["game_leadForm_json"]["field"]

                // console.log(JSON.stringify(state.fields[action.payload?.fieldId]),"state.fields[action.payload?.fieldId]")


            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings)
            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
            }catch(err) {
                // console.log(err)
            }

        },
        // setDeleteLeadConatect
        setRemoveConatent(state, action) {

            const id = state.fields
            const _id = _.cloneDeep(id[action.payload?.fieldId])
            _id["filedContent"]["game_leadForm_json"]["field"].splice(action.payload["index"], 1)
            id[action.payload?.fieldId] = _id
        },
        setCreateTEmplateState(state, action){
            state.createTemplate = action.payload
        },
        // add ProductSelectList

        setAddProductSelectList(state, action) {
            state.fields[action.payload?.fieldId]?.filedContent?.game_resultcreen_json.productSelectList.push(action.payload?.productData)
            // console.log(state.fields)
        },

        setProductCC(state, action) {
            state.fields[action.payload?.key]['filedContent'][action.payload?.node][action.payload?.child][action.payload?.productIndex][action.payload?.edge] = action.payload?.value
            // console.log(state.fields)
        },

        // remove ProductSelectList

        setRemoveSelectList(state, action) {

            state.fields[action.payload?.fieldId]?.filedContent?.game_resultcreen_json?.productSelectList.splice(action.payload.index, 1)

        },

        setProductCondition(state, action) {

            if(!state.autosaving) state.autosaving = true

            let Generatekey = Math.random().toString(16).slice(8) + Date.now().toString(36);

            if(!state.fields[action.payload?.fieldId]['filedContent']['game_resultcreen_json']['results'][action.payload?.resultIndex]['FilterCondition']) state.fields[action.payload?.fieldId]['filedContent']['game_resultcreen_json']['results'][action.payload?.resultIndex]['FilterCondition'] = {}

            if(action.payload?.Isadd){
                state.fields[action.payload?.fieldId]['filedContent']['game_resultcreen_json']['results'][action.payload?.resultIndex]['FilterCondition'][Generatekey] = action.payload?.value;
            }
            
            else if(action.payload?.IsDelete){
                delete state.fields[action.payload?.fieldId]['filedContent']['game_resultcreen_json']['results'][action.payload?.resultIndex]['FilterCondition'][action?.payload?.filterKey]  
            }
            else {
                state.fields[action.payload?.fieldId]['filedContent']['game_resultcreen_json']['results'][action.payload?.resultIndex]['FilterCondition'][action?.payload?.filterKey][action?.payload?.edge] = action?.payload?.value
            }

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

             TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
    

        },


        //  add additional  info  

        setAdditionalInfo(state, action) {

            state.Campaign_info.additional_info = action.payload.additional_info

            // console.log(state.Campaign_info.additional_info)

        },

        setActiveQuestions(state, action){
            state.activeQuestion.screen = action.payload?.screen
            state.activeQuestion.index = action.payload?.index
        },

        setUserDetail(state, action) {            
            state.userDetail.user_id = action.payload.user_id
            state.userDetail.profile = action.payload.profile
            state.userDetail.email = action.payload.email
            state.userDetail.last_name = action.payload.last_name
            state.userDetail.first_name = action.payload.first_name
            state.userDetail.engage_user_role = action.payload.engage_user_role
            state.userDetail.primary_Site_id = action.payload.primary_Site_id
            state.userNotification = action.payload?.notifications ?? [];
            state.userDetail.plan = action.payload?.plan?.plan ?? 'free';
            state.userPlan = action.payload?.plan ?? {}
        },
        setisEcommerceFields(state, action) {
            state.isEcommerceFields = action.payload
        },
        setEcommerceFieldID(state, action) {
            state.EcommerceFieldID = action.payload
        },
        setclearEditor(state, action) {
            state.isEcommerceFields = false
            state.createTemplate = false
            state.fieldsOrder = []
            state.EditorFields = []
            state.fields = {}
            state.chooseTemplate=false
        },
        setCampaign(state, action) {
            state.Campaign_info.Campaign_id = action.payload.Campaign_id
            state.Campaign_info.project_id = action.payload.project_id
            state.Campaign_info.name = action.payload.name
            state.Campaign_info.status = action.payload.status
            state.Campaign_info.campaign_update = action.payload.campaign_update
            state.Campaign_info.additional_info = JSON.parse(action.payload?.additional_info) ?? {}
            
            
            state.Campaign_info.rule = typeof action?.payload?.rules==="string" ?  JSON.parse(action?.payload?.rules ?? '{}') : action?.payload?.rules
        },
        setCampaignAdditional_info(state, action) {
            if (action.payload?.isTypechange) {

                state.Campaign_info.additional_info[action.payload?.key][action.payload.node][action.payload?.pollindex]["option"] = action.payload?.value

            } else {
                state.Campaign_info.additional_info[action.payload?.key][action.payload.node][action.payload?.pollindex].total = action.payload?.valueQue
                state.Campaign_info.additional_info[action.payload?.key][action.payload.node][action.payload?.pollindex].option[action.payload?.pollOptionindex] = action.payload?.valueoption

            }
        },
        setEditorResult(state, action) {
            if (action.payload?.Events) {
                state.EditorEvent.total_views = action.payload?.Events?.total_views
                state.EditorEvent.total_clicks = action.payload?.Events?.total_clicks
                state.EditorEvent.total_subscribe = action.payload?.Events?.total_subscribe
            }
            action.payload?.Analytics?.map(list => {
                let rendered_device = JSON.stringify(list.rendered_device)
            })
        },
        setChangeDevice(state, action) {
            state.device = action.payload;

        },
        SetAddSites(state, action) {
            state.sitesInfo = action.payload

            let temp = ['Select Domain']
            for (let i = 0; i < action.payload?.length; i++) {

                
                temp = [...temp, action.payload[i]?.domain]
                state.domain=temp
                // if (Array(state.domain).indexOf(action.payload[i]?.domain) < 0) state.domain = [...state.domain, action.payload[i]?.domain]
            }

        },
        setCampaignUpdate(state, action) {
            state.Campaign_info[action.payload?.type] = action.payload?.value

        },
        setUpdateCampaign(state, action) {
            if(!state.autosaving) state.autosaving = true

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules":JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
            
        },
        setAddTemplateData(state, action) {

            if(!state.autosaving) state.autosaving = true

            state.fieldsOrder = [...state.fieldsOrder, action.payload?.order[0]];
            state.fields[action.payload?.order[0]] = action.payload?.fileds[action.payload?.order[0]]

            let objTemplate = {
                templatetype: action.payload?.fileds[action.payload?.order[0]]?.templatetype,
                type: action.payload?.fileds[action.payload?.order[0]]?.type,
                category: action.payload?.fileds[action.payload?.order[0]]?.category,
            }

            state.EditorFields = [...state.EditorFields, { ...objTemplate, fieldId: action.payload?.order[0] }];

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)

        },
        setEditorFields(state, action) {

            if(!state.autosaving) state.autosaving = true

            let key = Math.random().toString(32).slice(4) + Date.now().toString(36);

            let filedContentobj = {}


            state.EditorFields = [...state.EditorFields, { ...action.payload, fieldId: key }];

            action.payload?.fieldProps?.map((list) => {
                filedContentobj[list.type] = list?.defaultVaule
            })


            state.fields[key] = {
                type: action.payload?.type,
                templatetype: 'untemplate',
                category: 'common',
                filedContent: filedContentobj
            }

            state.fieldsOrder = [...state.fieldsOrder, key]

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)

        },
        setEditorFieldsFormSever(state, action) {
            
            if (state.fieldsOrder.length !== 0 && Object.keys(state.fields).length !== 0) {

                state.fieldsOrder.map((fieldId) => {

                    const previewObj = state.EditorFields.findIndex((list) => list?.fieldId == fieldId)

                    if (state.fields[fieldId].templatetype !== 'template' && previewObj < 0) {

                        const payload = action?.payload ?? commonFileds

                        state.EditorFields = [...state.EditorFields, { ...payload[state.fields[fieldId].type], category: 'common', templatetype: 'untemplate', fieldId: fieldId }];

                    }
                    if (state.fields[fieldId].templatetype == 'template' && previewObj < 0) {

                        let objTemplate = {
                            templatetype: state.fields[fieldId].templatetype,
                            type: state.fields[fieldId].type,
                            category: state.fields[fieldId].category,
                        }

                        state.EditorFields = [...state.EditorFields, { ...objTemplate, fieldId: fieldId }];

                    }

                })
            }
        },
        setFieldsOrder(state, action) {
            state.fieldsOrder = action.payload;
        },
        setFields(state, action) {
            state.fields = action.payload
        },
        setFieldTempCC(state, action) {
            state.fields[action.payload.key][action.payload.fieldname] = action.payload.value
        },
        setTriggerToTemp(state, action) {
            let filedcontentData = {..._.cloneDeep(state.fields[action.payload.key]['filedContent'])}
            let FiledTEmpDAta = {..._.cloneDeep(state.fields[action.payload.key])}
            delete filedcontentData.game_startscreen_json
            delete filedcontentData.game_mainpage_json
            delete filedcontentData.game_resultcreen_json
            delete filedcontentData.game_leadForm_json

            const payload = {
                "category": FiledTEmpDAta.category,
                "Industry": FiledTEmpDAta.Industry,
                "image_src": FiledTEmpDAta.image_src,
                "gametype": FiledTEmpDAta.type,
                "name": FiledTEmpDAta.name,
                "description":FiledTEmpDAta.description,
                "settings": filedcontentData,
                "game_startscreen_json": FiledTEmpDAta['filedContent']['game_startscreen_json'],
                "game_mainpage_json": FiledTEmpDAta['filedContent']['game_mainpage_json'],
                "game_resultcreen_json": FiledTEmpDAta['filedContent']['game_resultcreen_json'],
                "game_leadForm_json": FiledTEmpDAta['filedContent']['game_leadForm_json'] ?? {}
            }
            if(action.payload.update) payload['template_id'] = action.payload.template_id
            createTemplate({template:payload}) 

        },
        setFieldContentChange(state, action) {
            if(!state.autosaving) state.autosaving = true

                    if (action.payload.fieldname !== "background") {state.fields[action.payload.key]['filedContent'][action.payload.fieldname] = action.payload.value
        }
             if (action.payload.fieldname === "background") {
                // state.fields[action.payload.key]['filedContent'][action.payload.fieldname] = {}
                if (!state.fields[action.payload.key]['filedContent'][action.payload.fieldname]) state.fields[action.payload.key]['filedContent'][action.payload.fieldname] = {}
                state.fields[action.payload.key]['filedContent'][action.payload.fieldname][action.payload.value.key] = action.payload.value.value
            }

            const fieldsContentkeys = Object.keys(state.fields[action.payload.key]['filedContent']) ?? [];
            const leadfromExist = fieldsContentkeys.findIndex(list => list == 'game_leadForm_json')
         
            if (((action.payload.fieldname === 'leadfrom') && (leadfromExist < 0)) || ((action.payload.fieldname === 'leadfrom') &&  (_.cloneDeep(state.fields[action.payload.key]['filedContent']["game_leadForm_json"]['field'])))) {
 
                let addobj = {
                    title: "Win a price!",
                    titleStatus: true,
                    imageSrc: "https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/award.png",
                    imageStatus: true,
                    buttenText: "Submit",
                    field: [
                        {
                            Label: "Email",
                            inputType: "email",
                            delectable: true,
                            editable: true,
                            required:true
                        }, {
                            Label: "Name",
                            inputType: "text",
                            delectable: true,
                            editable: true,
                            required:true
                        }
                    ]
                }
                state.fields[action.payload.key]['filedContent'] = { ...state.fields[action.payload.key]['filedContent'], game_leadForm_json: addobj }
            }
            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 
            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
        },
        setFieldTemplatePairChange(state, action) {
            if(!state.autosaving) state.autosaving = true

            const Layout = action.payload.value;
            const LayoutRange = Layout.split('x');

            let pairlength = state.fields[action.payload.key]['filedContent'][action.payload.node].pairItems;

            if ((LayoutRange[0] * LayoutRange[1]) < pairlength.length) pairlength = pairlength.splice((LayoutRange[0] * LayoutRange[1]), pairlength.length)

            if ((LayoutRange[0] * LayoutRange[1]) > pairlength.length) {

                let addpairitems = Math.abs((LayoutRange[0] * LayoutRange[1]) - pairlength.length)


                let count = 0;
                let tempkey = '';

                for (let i = 0; i < addpairitems; i++) {

                    let key = Math.random().toString(16).slice(8) + Date.now().toString(36);

                    if (count === 0) { tempkey = key; count++ }

                    else count--;

                    let obj = {
                        type: "image",
                        pairValue: tempkey,
                        src: "/asset/images/gametemplate/Red_apple_balloon_perspective_matte_s1.png",
                        content: "",
                    }

                    pairlength.push(obj)
                }

            }


            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)

        },
        setAutoSavingAction(state,action){
            state.autosaving = action.payload
        },
        setMatchTemplatePairChange(state, action) {
            if(!state.autosaving) state.autosaving = true

            const LayoutRange = 2 * action.payload.value;

            let pairlength = state.fields[action.payload.key]['filedContent'][action.payload.node].pairItems;

            if (LayoutRange < pairlength.length) pairlength = pairlength.splice((LayoutRange), pairlength.length)

            if (LayoutRange > pairlength.length) {

                let addpairitems = Math.abs(LayoutRange - pairlength.length)

                let count = 0;
                let tempkey = '';

                for (let i = 0; i < addpairitems; i++) {

                    let key = Math.random().toString(16).slice(8) + Date.now().toString(36);

                    if (count === 0) { tempkey = key; count++ }

                    else count--;
                    let obj = {
                        type: "text",
                        pairValue: tempkey,
                        src: "",
                        content: "Text",
                    }

                    pairlength.push(obj)
                }

            }

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)


        },
        setFieldGameFindFlowCC(state, action) {
            if(!state.autosaving) state.autosaving = true

            state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge] = action.payload.value;
            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }
            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
        },
        setWheelSegCC(state, action) {
            if(!state.autosaving) state.autosaving = true

            state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge] = action.payload.value;
            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
        },
        setWheelMainCC(state, action) {
            if(!state.autosaving) state.autosaving = true

            state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child] = action.payload.value;
            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
        },
        setProductDetail(state, action) {
            if (action.payload.isAdded) {//child
                if (state.fields[action.payload.key]['filedContent']["game_resultcreen_json"]['productSelectList'].length === 0) state.fields[action.payload.key]['filedContent']["game_resultcreen_json"]['productSelectList'].push(action.payload.value)
                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child].push(action.payload.value);
            }
        },setFormulaDetail(state, action){
            if(action.payload.isCalAdd){
                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child].push(action.payload.value)
            }else  state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.resultIndex]=action.payload.value
        },
        setFieldTemplateREsultCC(state, action) {
            if(!state.autosaving) state.autosaving = true

            if (action.payload.isCalAdd) {
                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.resultIndex][action.payload.edge].push(action.payload.value)
            }
            else if (action.payload.isCaldelete) state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.resultIndex][action.payload.edge].splice(action.payload.value,1)
            else state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.resultIndex][action.payload.edge] = action.payload.value

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }
             TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
        },

        addThankYouResult(state, action){
            if(!state.autosaving) state.autosaving = true

            let process=action.payload.process;
            if(process==="add") state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child].push(action.payload.value)            
            if(process==="delete" && state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child].length>1)
            state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child].splice(action.payload.value, action.payload.value);

            if(process==="add" || process==="delete") {
            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

             TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
        }
        },
        setFieldTemplateREsulCaltCC(state, action) {
            if (action.payload.isCalAdd) {
                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.resultIndex][action.payload.edge].push(action.payload.value)
            }
            else state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.resultIndex][action.payload.edge][action.payload.CalIndex][action.payload.Calkey] = action.payload.value
        },
        setFieldTemplateContentChange(state, action) {

            if(!state.autosaving) state.autosaving = true

            if (action.payload.child === "productDetail") {
                if (!state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child]) state.fields[action.payload.key]['filedContent'][action.payload.node] = action.payload.child
                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.value.key] = action.payload.value.value;

            }
            else if (action.payload.isCommonSetting && action.payload.child !== "productDetail") state.fields[action.payload.key]['filedContent'][action.payload.fieldname] = action.payload.value;

            else state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child] = action.payload.value;


            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 


            }



            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)

        },

        setOddOneLayout(state, action) {
            if(!state.autosaving) state.autosaving = true

            let rowDiff = action.payload.rowDiff
            let colDiff = action.payload.colDiff
            let frameArray = action.payload.frameArray
            let totalFrames = action.payload.totalFrames
            let obj = action.payload.obj
            let row = action.payload.row
            let col = action.payload.col

       
              
           // Column cleaner    
            if (colDiff !== 0) {
                for (let i = 0; i < totalFrames; i++) {
                            
                    if (colDiff > 0) {
                            // each row
                            for (let j = 0; j < colDiff; j++) {
                                let obj = state.fields[action.payload.key]['filedContent'][action.payload.node]["gameFrames"]["Frame" + (i + 1)] 
                                // each object
                                for(let k=0;k<obj?.length;k++){
                                       obj[k].push({
                                        "type": "evenElem",
                                        "imageUrl": "https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/christmas_toy.png"
                                    })
                                }
                            }

                    } else if (colDiff < 0) { 
                            // each row
                            for (let j = 0; j < Math.abs(colDiff); j++) { 
                                let obj = state.fields[action.payload.key]['filedContent'][action.payload.node]["gameFrames"]["Frame" + (i + 1)] 
                                // each object
                                for(let k=0;k<obj?.length;k++){
                                       obj[k]=obj[k].splice(0, col)
                                
                                 }
                    }
                    
                   }                 
                }
            }

            // Row cleaner
            if (rowDiff !== 0) {
            for (let i = 0; i < totalFrames; i++) {
                        
                if (rowDiff > 0) {
                        for (let j = 0; j < rowDiff; j++) {state.fields[action.payload.key]['filedContent'][action.payload.node]["gameFrames"]["Frame" + (i + 1)].push(obj) }

                } else if (rowDiff < 0) { 
                        for (let j = 0; j < Math.abs(rowDiff); j++) { state.fields[action.payload.key]['filedContent'][action.payload.node]["gameFrames"]["Frame" + (i + 1)] = state.fields[action.payload.key]['filedContent'][action.payload.node]["gameFrames"]["Frame" + (i + 1)].splice(0, row) }
                }
                
            }                 
            } 


            state.fields[action.payload.key]['filedContent'][action.payload.node]["layoutrange"] = action.payload.newLayout
            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)

        },
        setOddOneFrameAdd(state, action) { 
            if(!state.autosaving) state.autosaving = true

            if (action.payload.type === "add") {
                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.edge] = action.payload.value
                let localValue=state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child]

                let inx=1, newValue={};
                for (const key in localValue) {
                    if (localValue.hasOwnProperty(key)) {
                      newValue["Frame"+inx]=localValue[key];
                      inx+=1
                    }
                  }
                  state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child]=newValue

            
            } else if (action.payload.type === "delete") {
                let  localValue=state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child]

                delete localValue[action.payload.edge]

                let inx=1, newValue={};
                for (const key in localValue) {
                    if (localValue.hasOwnProperty(key)) {
                      newValue["Frame"+inx]=localValue[key];
                      inx+=1
                    }
                  }
                  state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child]=newValue


                // for(let i=0;i<localValue.length; i++){
                //     state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child]["Frame"+(i+1)]=localValue[i]
                // }

            }

            if (action.payload.type === "add" || action.payload.type === "delete") {
                const paytload = {
                    "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                    "status": state.Campaign_info.status,
                    "campaign_update": state.Campaign_info.campaign_update,
                    "order": JSON.stringify(state.fieldsOrder),
                    "fileds": JSON.stringify(state.fields),
                    "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                    "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                    "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 
                }

                 if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
            }
        },

        setCharDropLayout(state, action) {
            if(!state.autosaving) state.autosaving = true

            let rowCount = state.fields[action.payload.key]['filedContent'][action.payload.node]["wordlist"]?.length,
                newValue = action.payload.value + "x" + rowCount

            state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child] = newValue;

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 
            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)


        },

        upadatRowLayout(state, action) {
            if(!state.autosaving) state.autosaving = true

            let rowCount = state.fields[action.payload.key]['filedContent'][action.payload.node]["wordlist"]?.length,
                layOut = (state.fields[action.payload.key]['filedContent'][action.payload.node]["layoutrange"]?.toLowerCase())?.split("x"),
                newValue = layOut[0] + "x" + rowCount

            state.fields[action.payload.key]['filedContent'][action.payload.node]["layoutrange"] = newValue;

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 
                
            }
            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)


        },
        setPuzzelAddWord(state, action) {
            if(!state.autosaving) state.autosaving = true

            if (action.payload.child === 'wordlist' || action.payload.child === 'words') {
                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child] = [...state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child], action.payload.value];

                const paytload = {
                    "name": state.Campaign_info.name,
                    "project_id": state.Campaign_info.project_id,
                    "status": state.Campaign_info.status,
                    "campaign_update": state.Campaign_info.campaign_update,
                    "order": JSON.stringify(state.fieldsOrder),
                    "fileds": JSON.stringify(state.fields)
                }

                if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
            }

        },

        setPuzzelDeleteWord(state, action) {
            if(!state.autosaving) state.autosaving = true

            if (action.payload.child === 'words' || action.payload.child === 'wordlist') {
                let wordArray = state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child]
                if (action.payload.value !== "" && wordArray.includes(action.payload.value)) {
                    wordArray.splice(wordArray.indexOf(action.payload.value), 1);

                    state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child] = wordArray;

                    const paytload = {
                        "name": state.Campaign_info.name,
                        "project_id": state.Campaign_info.project_id,
                        "status": state.Campaign_info.status,
                        "campaign_update": state.Campaign_info.campaign_update,
                        "order": JSON.stringify(state.fieldsOrder),
                        "fileds": JSON.stringify(state.fields),
                        "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                        "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                        "rules": JSON.stringify(state.Campaign_info.rule),
                        "settings":JSON.stringify(state.campaignSettings) 
                    }
                    if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
                }
            }

        },
        setConnetionCC(state, action) {
            if(!state.autosaving) state.autosaving = true

            if (action.payload.child === 'gameFrames') {
                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.optionIndex][action.payload.innerINdex][action.payload.edge] = action.payload.value;
            } else {

                if (action.payload?.add) state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge]?.push(action.payload.value)
                else if(action.payload?.remove) state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge]?.splice(action.payload.optionIndex,1)
                else state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge][action.payload.optionIndex] = action.payload.value;
            }

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 
            }
            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
        },
        setAddOption(state, action) {
            if(!state.autosaving) state.autosaving = true

            if(action.payload.remove) state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge]?.splice(action?.payload?.optionIndex,1)
            else state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge].push(action.payload.value)
        
            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 
            }
             TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
      
        },
        setRuleAddOption(state, action) {

            if (!state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge]) state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge] = {}

            if (!state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge][action.payload.rulesKey]) {

                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge][action.payload.rulesKey] = {}
                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge]['action'] = {}
            }

            let key = Math.random().toString(32).slice(4) + Date.now().toString(36);
            if (action.payload.ConditionKeys !== "") state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge][action.payload.rulesKey][action.payload.ConditionKeys]['pairQue'] = key
            state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge][action.payload.rulesKey][key] = action.payload.value;

        },

        setRemoveRuleCondistion( state, action ){
            const stateObj = state;
   
            let rule = stateObj.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge][action.payload.rulesKey];

            if( action.payload.pair_id !== "" )rule[action.payload.pair_id].pairQue = "";
  
            delete rule[action.payload.connection_id] ;
 
        },
        setRuleAddOptionCC(state, action) {
            if(!state.autosaving) state.autosaving = true

            if (!state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge]) {

                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge] = {}
            }
            if (action.payload.rulesKey === 'action') {
                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge][action.payload.rulesKey][action.payload.value.key] = action.payload.value.value
            }
            if (action.payload.rulesKey === 'conditions') {
                let ObjecyKey = Object.keys(state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge][action.payload.rulesKey]) ?? []

                let FIndEx = ObjecyKey.findIndex(list => list === action.payload.ConditionKeys)

                if (action.payload.value.key === 'condition') state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge][action.payload.rulesKey][action.payload.ConditionKeys]['pairQue'] = ObjecyKey[FIndEx + 1]
                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge][action.payload.rulesKey][action.payload.ConditionKeys][action.payload.value.key] = action.payload.value.value
            }
            if (action.payload.rulesKey === 'DefaultAction') state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge][action.payload.rulesKey] = action.payload.value



            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 
            }
             TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
        },
        setQuizQuestionCC(state, action) {

            const { templateType, edge, value, optionIndex, activeSateAnsInd } = action.payload;
console.log("innnnnn..........", edge)
            
            if(!state.autosaving) state.autosaving = true;

            let copyObject = { ...state.fields };
           
            if ((edge == "option") && (templateType == 'personalize-quiz')) state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex]['options'][optionIndex][action.payload.edge] = action.payload.value;

            else if ((edge == "results") && (templateType == 'personalize-quiz')) {
                
                let getArr = copyObject[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex]['options'][optionIndex][action.payload.edge] ?? [];
                
                const getData = getArr?.findIndex( list => list == value );
                
                if ( getData >  -1 ) { getArr?.splice(getData,1);console.log('test-2') }
                
                else getArr.push( value );
                
                state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex]['options'][optionIndex][action.payload.edge] = getArr;
            }
            
            else if ((edge !== "options") && ( edge !== "range" )) state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge] = action.payload.value;
            
            else if(edge === "range") {


                if( typeof state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex]['range'] != 'object' ) state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex]['range'] = {};
                let temp = { ...state };
                temp.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][edge][action.payload.optionKey] = action.payload.value;  
                state = temp

            } 
            // if( action.payload.edge=== "optionType" && action.payload.value === "yesorno") state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex].answer = 'Yes'
            else {

                if (action.payload.child !== "questionsList") {

                    state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge][action.payload.optionIndex] = action.payload.value;
                    
                    if( activeSateAnsInd ) state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex]['answer'] = action.payload.value; 
                }
                
                else state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][action.payload.QueIndex][action.payload.edge][action.payload.optionIndex][action.payload.optionKey] = ((action.payload.optionKey==="value" && isNotEmpty(action.payload.optionKey)) ?  parseInt(action.payload.value) : action.payload.value );
            
            }

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)

        },

        questionIdAdd( state, action ) {

            const { key, node , child, childInd } = action.payload;

            let IdValue = Math.random().toString(16).slice(8) + Date.now().toString(36);

            state.fields[key]['filedContent'][node][child][childInd]['questionId'] = IdValue

        },

        setQuizQuestionAdd(state, action) {
            if(!state.autosaving) state.autosaving = true

            state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child].push(action.payload.value)

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)


        },


        setFindFlowGameLevelAdd(state, action) {
            if(!state.autosaving) state.autosaving = true

            state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child].push(action.payload.value)

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)

        },
        setWheelSegmentAdd(state, action) {
            if(!state.autosaving) state.autosaving = true

            state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child].push(action.payload.value)
             
            let tempsegmentValuesArray=[]
            state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child].map((obj,inx)=>{
                obj.slicenumber=inx+1;
                tempsegmentValuesArray.push(obj)
            })
            state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child]=tempsegmentValuesArray;
           
            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)

        },



        setQuizQuestionRA(state, action) {
            if(!state.autosaving) state.autosaving = true

            let selectedFieldpos = action.payload?.position
            let QueArr = state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child]
            let SetectedQues = state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos]


            // eslint-disable-next-line default-case
            switch (action.payload?.type) {
                case 'down':
                    if (selectedFieldpos + 1 < QueArr.length) {

                        state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos] = state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos + 1];
                        state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos + 1] = SetectedQues;
                    }
                    break;
                case 'up':
                    if (selectedFieldpos - 1 > -1) {
                        state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos] = state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos - 1];
                        state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos - 1] = SetectedQues;
                    }
                    break;
                case 'delete':
                    state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child].splice(selectedFieldpos, 1);
                    break;

            }
            
            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)


        },

        setQuizAns(state, action) { 
            if(!state.autosaving) state.autosaving = true

                let selectedFieldpos = action.payload?.position
               state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos][action.payload.edge]=action.payload.value
 
            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
        },


        setFindFlowRA(state, action) {
            if(!state.autosaving) state.autosaving = true

            let selectedFieldpos = action.payload?.position
            let QueArr = state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child]
            let SetectedQues = state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos]

            // eslint-disable-next-line default-case
            switch (action.payload?.type) {
                case 'delete':
                    state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child].splice(selectedFieldpos, 1);
                    break;

            }
            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)

        },
        setWheelSegRA(state, action) {
            if(!state.autosaving) state.autosaving = true

            let selectedFieldpos = action.payload?.position
            let QueArr = state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child]
            let SetectedQues = state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos]


            // eslint-disable-next-line default-case
            switch (action.payload?.type) {
                case 'down':
                    if (selectedFieldpos + 1 < QueArr.length) {
                        state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos] = state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos + 1];
                        state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos + 1] = SetectedQues;
                    }
                    break;
                case 'up':
                    if (selectedFieldpos - 1 > -1) {
                        state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos] = state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos - 1];
                        state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child][selectedFieldpos - 1] = SetectedQues;
                    }
                    break;
                case 'delete':
                    state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child].splice(selectedFieldpos, 1);
                    let tempsegmentValuesArray=[]
                    state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child].map((obj,inx)=>{
                        obj.slicenumber=inx+1;
                        tempsegmentValuesArray.push(obj)
                    })
                    state.fields[action.payload.key]['filedContent'][action.payload.node][action.payload.child]=tempsegmentValuesArray;
                    break;

            }
            const paytload = {
                
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)


        },

        setpairContentChange(state, action) {
            if(!state.autosaving) state.autosaving = true

            let pairlist = state.fields[action.payload.key]['filedContent'][action.payload.node].pairItems;
            let gerpair = pairlist?.filter(list => list.pairValue === action.payload.pairvalue);

            gerpair[action.payload.pairIndex].type = action.payload.type;
            if (action.payload.type === 'image') gerpair[action.payload.pairIndex].src = action.payload.value
            else gerpair[action.payload.pairIndex].content = action.payload.value

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 
            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
        },
        setRearragepairContentChange(state, action) {
            if(!state.autosaving) state.autosaving = true

            let pairlist = state.fields[action.payload.key]['filedContent'][action.payload.node].pairItems;
            // let gerpair = pairlist?.filter(list => list.pairValue === action.payload.pairvalue);

            pairlist[action.payload.pairIndex].type = action.payload.type;
            if (action.payload.type === 'image') pairlist[action.payload.pairIndex].src = action.payload.value
            else pairlist[action.payload.pairIndex].content = action.payload.value

            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
        },
        setFieldRearrange(state, action) {
            if(!state.autosaving) state.autosaving = true

            let selectedFieldpos = action.payload?.position
            let selectedField = state.fieldsOrder[selectedFieldpos];
            let selectedEditorFields = state.EditorFields[selectedFieldpos];


            // eslint-disable-next-line default-case
            switch (action.payload?.type) {
                case 'down':
                    if (selectedFieldpos + 1 < state.fieldsOrder.length) {
                        state.fieldsOrder[selectedFieldpos] = state.fieldsOrder[selectedFieldpos + 1];
                        state.fieldsOrder[selectedFieldpos + 1] = selectedField;
                        state.EditorFields[selectedFieldpos] = state.EditorFields[selectedFieldpos + 1];
                        state.EditorFields[selectedFieldpos + 1] = selectedEditorFields;
                    }
                    break;
                case 'up':
                    if (selectedFieldpos - 1 > -1) {
                        state.fieldsOrder[selectedFieldpos] = state.fieldsOrder[selectedFieldpos - 1];
                        state.fieldsOrder[selectedFieldpos - 1] = selectedField;
                        state.EditorFields[selectedFieldpos] = state.EditorFields[selectedFieldpos - 1];
                        state.EditorFields[selectedFieldpos - 1] = selectedEditorFields;
                    }
                    break;
                case 'delete':

                    delete state.fields[selectedField];
                    state.fieldsOrder.splice(selectedFieldpos, 1);
                    state.EditorFields.splice(selectedFieldpos, 1);
                    break;

            }


            const paytload = {
                "name": state.Campaign_info.name,
                "project_id": state.Campaign_info.project_id,
                "status": state.Campaign_info.status,
                "campaign_update": state.Campaign_info.campaign_update,
                "order": JSON.stringify(state.fieldsOrder),
                "fileds": JSON.stringify(state.fields),
                "additional_info": JSON.stringify(state.Campaign_info.additional_info),
                "pollResp": JSON.stringify(state.Campaign_info.pollResp),
                "rules": JSON.stringify(state.Campaign_info.rule),
                "settings":JSON.stringify(state.campaignSettings) 

            }

            if(!state.createTemplate) TriggerCampaignUpdate(paytload, state.Campaign_info.Campaign_id,state.SharedWS)
        },
        setEditor(state, action) {
            state.EditorFields = action.payload
        }
    }
})

export const Actions = cunerSlice.actions;

export const store = configureStore({
    reducer: cunerSlice.reducer,
    // devTools: {

    // }
});